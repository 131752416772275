.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
}
.loading .loading-img {
  position: relative;
  width: 4rem;
  height: 4rem;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#reserve_details {
  /* 列表为空*/
}
#reserve_details .withdraw {
  font-size: 0.24rem;
  color: #409eff;
  padding-left: 0.3rem;
  position: relative;
}
#reserve_details .withdraw img {
  position: absolute;
  width: 0.26rem;
  top: 0;
  left: 0;
}
#reserve_details .yd-list-donetip {
  display: none;
}
#reserve_details .empty-box {
  background: #fff;
  height: 100vh;
}
#reserve_details ul {
  border-bottom: 1px solid #eee;
  padding: 0.3rem 0.2rem;
  background: #fff;
}
#reserve_details ul .show-content {
  color: #fff;
  background: #409eff;
  font-size: 0.24rem;
  padding: 0.1rem 0;
  border-radius: 0.1rem;
  margin: 0.06rem 0;
}
#reserve_details ul .show-content span {
  display: inline-block;
}
#reserve_details ul .show-content .content-left {
  padding-left: 10%;
  width: 65%;
}
#reserve_details ul .show-content .content-right {
  width: 35%;
  text-align: center;
}
#reserve_details ul .details {
  min-height: 0.7rem;
  height: 100%;
  position: relative;
}
#reserve_details ul li {
  height: 0.46rem;
  line-height: 0.46rem;
  color: #999;
  font-size: 0.24rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#reserve_details ul li .details_title {
  width: 30%;
}
#reserve_details ul li span {
  display: inline-block;
}
#reserve_details ul li .details_name {
  color: #000;
  font-size: 0.28rem;
}
#reserve_details ul li .arrow {
  width: 0.26rem;
  position: absolute;
  right: 0.4rem;
  top: 0rem;
  margin: 0.2rem;
}
#reserve_details ul li .details_sty {
  width: 1.5rem !important;
  float: left;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 0.26rem;
  background: #409eff;
  position: relative;
  border-radius: 0.06rem;
  color: #fff;
  margin-right: 9%;
}
#reserve_details ul li .details_content {
  max-width: 60%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
